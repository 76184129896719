:root {
    --themeColor: linear-gradient(90deg, rgba(198,174,117,1) 20%, rgba(97,84,71,1) 100%);
    --silverColor: linear-gradient(140deg, rgba(255,255,255,1) 20%, rgba(153,153,153,.7) 100%);
    --gradient: linear-gradient(180deg, rgba(198,174,117,1) 0%, rgba(97,84,71,1) 100%);
    --darkColor: #0D2647;
    --white: #FFFFFF;
    --gray: #F6F7FF;
    --text: #5F6B7A;
    --textPalceholder: #5C718D;
    --bg: #F3F6FA;

}