@import 'variables';
@import 'fonts';

html {
    position: relative;
    width: 100%;
}

.main-artical {
    overflow-x: hidden;
}

body {
    position: relative !important;
    font-family: var(--regular);
    font-style: normal;
    font-size: 15px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    background-color: var(--white);
    // cursor: none !important;
}

img {
    max-width: 100%;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

li {
    list-style: none
}

a {
    text-decoration: none;
    cursor: pointer;
}

a,
.btn {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
.btn:focus {
    text-decoration: none;
    outline: none
}

.title-container {
    a {
        color: white;
    }
}

p {
    font-size: 16px;
    font-family: var(--regular);
    // padding-bottom: 15px;
}

*::-moz-selection {
    background: var(--darkColor);
    color: #fff;
    text-shadow: none
}

*::-moz-selection {
    background: var(--darkColor) none repeat scroll 0 0;
    color: #fff;
    text-shadow: none
}

::selection {
    background: var(--darkColor);
    color: #fff;
    text-shadow: none
}

.gradient {
    background: var(--gradient);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

}

.themeColor {
    background: var(--themeColor);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.silverColor {
    background: var(--silverColor);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.gradient-bg {
    background: var(--gradient);
}

.themeColor-bg {
    background: var(--themeColor);
}

.SilverColor-bg {
    background: var(--silverColor);
}

.darkColor-bg {
    background: var(--darkColor);
}

.Box-shadow {
    -webkit-box-shadow: 0px 40px 40px -25px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 40px 40px -25px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 40px 40px -25px rgba(0, 0, 0, 0.2);
}



// .container {
//     max-width: calc(100% - 54px) !important;
//     padding-left: 0 !important;
//     padding-right: 0 !important;
// }


.common-space {
    padding-top: 50px;
    padding-bottom: 50px;
}

.heading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.social-link {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -105px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100;
    height: 190px;
    flex-wrap: wrap;

    &>div {
        position: relative;
        right: 0;
        border-radius: 10px;
        width: 150px;
        padding: 8px;
        background: var(--gradient);
        transition: all 0.4s ease-in-out;

        &:hover {
            right: 30px;
        }
    }

    & svg {
        cursor: pointer;
        width: 28px;
    }
}

iframe {
    width: 100%;
    height: 450px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.after {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        width: 60px;
        height: 1px;
        top: 0px;
        bottom: 0;
        left: 130px;
        margin: auto;
        background-color: var(--darkColor);

    }
}


.before_after {
    position: relative;

    &::after,
    &::before {
        content: '';
        position: absolute;
        width: 60px;
        height: 1px;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: var(--darkColor);
    }

    &::after {
        left: -70px;
    }

    &::before {

        right: -70px;
    }
}

.before_after_white {
    position: relative;

    &::after,
    &::before {
        content: '';
        position: absolute;
        width: 60px;
        height: 1px;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: var(--white);
    }

    &::after {
        left: -70px;
    }

    &::before {

        right: -70px;
    }
}



.bg-setting {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.loading-btn {
    font-size: 18px;
    font-family: var(--semiBold);
    color: var(--white);
}


.spinner {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 3px solid;
    border-color: var(--darkColor) transparent;
    animation: spin 1s infinite ease-out;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}





footer {
    // background-image: url(../images/png/footer-bg.png);
    background-repeat: no-repeat;
    background-position: right 5px;
}





/***************** particals animation *************************/

.particals {
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
}

.circle-container {
    $particleNum: 200;
    $particleColor: hsl(180, 100%, 80%);

    position: absolute;
    transform: translateY(-10vh);
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    .circle {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        mix-blend-mode: screen;
        background-image: radial-gradient(hsl(180, 100%, 80%),
                hsl(180, 100%, 80%) 10%,
                hsla(180, 100%, 80%, 0) 56%);

        animation: fadein-frames 200ms infinite, scale-frames 2s infinite;

        @keyframes fade-frames {
            0% {
                opacity: 1;
            }

            50% {
                opacity: 0.7;
            }

            100% {
                opacity: 1;
            }
        }

        @keyframes scale-frames {
            0% {
                transform: scale3d(0.4, 0.4, 1);
            }

            50% {
                transform: scale3d(2.2, 2.2, 1);
            }

            100% {
                transform: scale3d(0.4, 0.4, 1);
            }
        }
    }

    $particleBaseSize: 3;

    @for $i from 1 through $particleNum {
        &:nth-child(#{$i}) {
            $circleSize: random($particleBaseSize);
            width: $circleSize + px;
            height: $circleSize + px;

            $startPositionY: random(10) + 100;
            $framesName: "move-frames-" + $i;
            $moveDuration: 28000 + random(9000) + ms;

            animation-name: #{$framesName};
            animation-duration: $moveDuration;
            animation-delay: random(37000) + ms;

            @keyframes #{$framesName} {
                from {
                    transform: translate3d(random(100) + vw, $startPositionY + vh, 0);
                }

                to {
                    transform: translate3d(random(100) + vw, -$startPositionY - random(30) + vh, 0);
                }
            }

            .circle {
                animation-delay: random(4000) + ms;
            }
        }
    }
}
.contact-info .contact-svg img{
    width: auto;
    height: 22px !important;

}


/***************** RTL DIrection*************************/

html[dir='rtl'] {
    position: relative !important;
    width: 100%;
    overflow-x: hidden;
}

body .rtl {
    position: relative !important;
    font-family: var(--arRegular) !important;
    font-style: normal;
    font-size: 15px;
    overflow-x: hidden;


}

.rtl .nav-bar-list a,
.rtl .footer-list a,
.rtl label {
    font-family: var(--arSemiBold) !important;
}

.rtl .nav-bar-list a.active {
    font-family: var(--arExtraBold) !important;
}

.rtl .loading-btn,
.rtl .Toastify__toast-body>div,
.rtl .field-box input::placeholder,
.rtl textarea::placeholder,
.rtl p,
.rtl .unList span,
.rtl input[type="file"]::after,
.rtl button:hover span,
.rtl .footer-op {
    font-family: var(--arRegular) !important;
}

.rtl button span,
.rtl button div,
.rtl .secondary-heading {
    font-family: var(--arMedium) !important;
}

.rtl .contact-social-links {
    position: relative;
    right: 15px;
}


.rtl .header-btn span,
.rtl .contact-text {
    direction: ltr;
}

.rtl .contact-text {
    font-family: var(--arSemiBold);
    text-align: end !important;
}

.rtl .change-Btn {
    position: fixed;
    bottom: 0;
    right: auto !important;
    left: 0;
}

.rtl .whatsapp {
    position: fixed;
    bottom: 0;
    left: auto !important;
    right: 0;
    z-index: 1;
}

.rtl .contact-svg,
.rtl .contact-img,
.rtl .header-btn svg,
.rtl .click-btn svg,
.rtl .service-list svg,
.rtl .service-list-container svg,
.rtl .successImgCol img,
.rtl .footer-list svg,
.rtl button svg,
.rtl .project-bg,
.rtl .worker-img {
    transform: scaleX(-1) !important;
    -webkit-transform: scaleX(-1) !important;
    -moz-transform: scaleX(-1) !important;
    -ms-transform: scaleX(-1) !important;
    -o-transform: scaleX(-1) !important;
}



.rtl .social-link {
    position: fixed;
    top: 0;
    bottom: 0;
    right: auto !important;
    left: -105px !important;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100;
    height: 190px;
    flex-wrap: wrap;

    &>div {
        position: relative;
        right: auto !important;
        left: 0 !important;
        border-radius: 10px;
        width: 150px;
        padding: 8px;
        background: var(--gradient);
        transition: all 0.4s ease-in-out;

        &:hover {
            left: 30px !important;
        }
    }

    & svg {
        cursor: pointer;
        width: 28px;
    }
}




//  adding 
.rtl #home {
    background-image: url(../images/png/banner-ar.png) !important;
}

.rtl .yellow::before {
    right: auto !important;
    left: 3px !important;
}

.rtl .white::before {
    left: auto !important;
    right: 3px !important;
}

.rtl .after::after {
    content: "";
    position: absolute;
    width: 60px;
    height: 1px;
    top: 0px;
    bottom: 0;
    left: auto;
    right: 80px;
    margin: auto;
    background-color: var(--darkColor);
}

.rtl .about-img-wrap-2 {
    right: auto !important;
    left: -50px;
}

.rtl .about-img-wrap-1 {
    left: auto !important;
    right: -50px;
}

.rtl .Choose-digital {
    position: relative;
    background-color: var(--gray);
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 40px 20px 0px 30px;
    border-left: 1px solid #D8DBEF;
}

.rtl .project-other .project-bg {

    right: auto !important;
    left: 40px;
}

.rtl .workers-man>span {
    right: auto !important;
    left: -50px;
    bottom: -273px !important;
}

.rtl .worker-logo {
    position: absolute;
    bottom: -5px;
    left: 0px !important;
    right: 0 !important;
    margin: auto;
}
.rtl .btn-text.align-items-center{
    
    & > div{
        margin-left: 10px !important;
        margin-right: 0 !important;
        img{
            transform: scaleX(-1) !important;
            -webkit-transform: scaleX(-1) !important;
            -moz-transform: scaleX(-1) !important;
            -ms-transform: scaleX(-1) !important;
            -o-transform: scaleX(-1) !important;
        }
    }
}

/***************** particals animation *************************/

.rtl .particals {
    right: 1600px;
}