@media (min-width: 1500px) {
  // .container {
  //   max-width: 1440px !important;
  // }
}

@media (max-width: 1399px) {
  .common-space {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .workers-man>span {
    bottom: -222px !important;
  }
}



@media (max-width: 1299px) {}

@media (max-width: 1199px) {


  .nav-bar-list {
    gap: 15px !important;
  }

  // .logo svg {
  //   width: 140px !important;
  // }

  .banner-content-wrapper>div.silverColor {
    font-size: 38px !important;
  }

  .main-heading {
    font-size: 30px !important;
  }

  .sub-heading {
    font-size: 18px !important;
  }

  .choose-heading {
    font-size: 24px !important;
  }

  p,
  .service-text {
    font-size: 15px !important;
  }

  .about-img-wrap-1,
  .about-img-wrap-2 {
    width: 260px;
  }

  .workers-man>span {
    bottom: -248px !important;
  }
  .worker-logo {
    right: 30px !important;
  }


  /* 

  .nav-bar-list {
    gap: 20px !important;
  }

  .footer-content>svg {
    width: 150px !important;
  }

  .footer-logo svg {
    width: 150px !important;
  }

  .footer-content>.gap-3 {
    gap: 0px !important;
  } */
}

@media (max-width: 991px) {

  .nav-bar-list {
    display: none !important;
    z-index: 110;
  }

  .toggleBtn {
    margin: 0 5px;
    display: block !important;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
  }

  .toggleBtn svg {
    color: var(--white);
  }

  .showToggle {
    position: absolute !important;
    top: 87px;
    left: 0;
    background: var(--darkColor) !important;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
    color: var(--white) !important;
    width: 100% !important;
    display: block !important;
    box-shadow: 0px 5px 15px #0ea3594f;
  }

  .showToggle div {
    border-bottom: 1px solid var(--white);
    outline: none;
    width: 100%;
    // padding: 20px 10px;
  }

  .showToggle.nav-bar-list a {
    background: none;
    padding: 10px 15px !important;
    display: flex;
    width: 100%;
  }

  .nav-bar-list a:hover::before,
  .nav-bar-list a.active::before {
    background: transparent !important;
  }

  .showToggle a:hover {
    background: var(--themeColor) !important;
    -webkit-text-fill-color: transparent !important;
    -webkit-background-clip: text !important;
  }

  .showToggle .active {
    background: var(--themeColor) !important;
    -webkit-text-fill-color: transparent !important;
    -webkit-background-clip: text !important;
  }

  .sticky.py-2 {
    transition: all 0.4s ease-in-out;
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
  }

  header {
    padding: 5px 0 !important;
  }

  section#home {
    height: auto !important;
    min-height: auto !important;
  }



  // adding css 

  .cursor-container {
    display: none;
  }


  .banner-content-wrapper {
    padding-top: 100px;
    padding-bottom: 130px;
    flex-wrap: wrap;
  }

  .about-rows {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .about-img-wrap-1,
  .about-img-wrap-2 {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    margin: 30px auto;
  }

  .about-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    padding: 0 20px;
  }

  .after::before {
    content: "";
    position: absolute;
    width: 60px;
    height: 1px;
    top: 0px;
    bottom: 0;
    right: 130px;
    margin: auto;
    background-color: var(--darkColor);
  }

  .common-space {
    padding: 30px 0 !important;
  }

  .product-wrapper {
    width: 70% !important;
  }

  .Choose-digital {
    border-right: none !important;
    border-radius: 20px 20px 0px 0px !important;
  }

  .comparing {
    margin-top: 30px !important;
    margin-bottom: 60px !important;
  }

  .workers-man {
    display: none !important;
  }

  .worker-logo {
    left: auto !important;
  }

  #gallery {
    margin-bottom: 30px !important;

    & .heading-content p {
      width: 100% !important;
    }
  }

  .gallery-wrapper-content>div:nth-child(2n+1)>div,
  .gallery-wrapper-content>div:nth-child(2n)>div,
  .gallery-wrapper-content-2>div:nth-child(2n+1)>div,
  .gallery-wrapper-content-2>div:nth-child(2n)>div {
    height: 386px !important;
  }

  .gallery-wrapper-content-2>div:nth-child(2n+1)>div,
  .gallery-wrapper-content-2>div:nth-child(2n)>div {
    position: relative;
    top: 0 !important;
    margin: 10px 0 !important;

  }

  .footer-row {
    flex-direction: column !important;
    flex-wrap: wrap !important;
    gap: 15px;

    &>div {
      justify-content: center;
      display: flex;

    }
  }

  .containerContact {
    padding-top: 60px !important;
    padding-bottom: 40px !important;
  }

  .f-logo-wrap {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }

  .portfolio-heading {
    text-align: center;
  }

  .heading-content {
    & .img-container {
      margin-bottom: 25px;
    }
  }

  .Operations-container {
    display: flex !important;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    height: 100% !important;
  }

  .Operations-box>div {
    position: relative;
    height: 210px !important;
  }

  .item-1 {
    height: auto !important;
  }

  .item-4 {
    height: auto !important;
  }

  .Operations-box .service-bg {
    background-position: top !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
  .contact-info a{
    margin-top: 10px;;
  }

}

@media (max-width: 768px) {

  .banner-content-wrapper {
    justify-content: center;
    text-align: center;

    &>div.silverColor {
      font-size: 32px !important;
    }

    & p {
      width: 70%;
    }

  }

  .main-heading {
    font-size: 26px !important;
  }

  .product-wrapper {
    width: 90% !important;
  }

  .sub-heading {
    font-size: 16px !important;
  }

  .faWrapper {
    display: none;
  }

  .contact-wrapper {
    flex-wrap: wrap;
  }

  .gallery-wrapper-content>div:nth-child(2n+1)>div,
  .gallery-wrapper-content>div:nth-child(2n)>div,
  .gallery-wrapper-content-2>div:nth-child(2n+1)>div,
  .gallery-wrapper-content-2>div:nth-child(2n)>div {
    height: 386px !important;
  }

  .contact-info {
    gap: 15px !important;
  }

  .footer-list {
    display: none !important;
  }

  .client-wrap {
    gap: 40px !important;
    padding: 30px 10px !important;
  }

  .achieve-box {
    padding: 0 15px !important;
    margin-top: 25px !important;
    height: 120px !important;
  }
}
@media (max-width: 767px) {
  .contact-info a {
    margin-top: 0px;
  }
}
@media (max-width: 576px) {

  .worker-logo {
    right: 30px !important;
    width: 60px !important;
  }

  #home .social-link {
    display: none;
  }

  .footer-revised>* {
    display: flex;
    justify-content: center;
  }

  .footer-op {
    text-align: center;
  }

  .choose-heading {
    text-align: center;
  }
  .whpPf{
    flex-wrap: wrap;
  }
  .worker-logo{
    display: none;
  }
  .bg-setting.workers .common-space.row{
    padding-bottom: 15px !important;
  }
}

@media (max-width: 480px) {
  header .header-btn {
    display: none !important;
  }

  .providing {
    font-size: 18px !important;
  }

  .main-heading {
    font-size: 25px !important;
    text-align: center;
  }

  p {
    font-size: 14px !important;
    text-align: justify;
  }

  .trained-worker {
    font-size: 22px !important;
    text-align: initial !important;
  }

  .client-wrap {
    gap: 60px !important;
  }

  .contact-info {
    gap: 15px !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: inherit !important;
  }

  .change-Btn,
  .whatsapp {
    width: 60px !important;
  }

  .Choose-digital {
    padding: 30px 15px 0px 15px !important;
  }
}


@media (max-width: 420px) {

  .banner-content-wrapper>div.silverColor {
    font-size: 25px !important;
  }

  .choose-heading {
    font-size: 22px !important;
  }
}

@media (max-width: 480px) {}

@media (max-width: 400px) {

  .before_after::after,
  .before_after::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 1px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--darkColor);
  }

  .before_after::before {
    right: -35px;
  }

  .before_after::after {
    left: -35px;
  }

  .before_after_white::after,
  .before_after_white::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 1px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--white);
  }

  .before_after_white::before {
    right: -35px;
  }

  .before_after_white::after {
    left: -35px;
  }

  .after::before,
  .after::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 1px;
    top: 0px;
    bottom: 0;
    margin: auto;
    background-color: var(--darkColor);
  }

  .after::before {
    right: 100px;
  }

  .after::after {
    left: 100px;
  }

  .project-other .project-bg {
    position: absolute;
    bottom: 0px;
    right: 20px !important;
    width: 100px !important;
  }
}

/***************** RTL DIrection*************************/

@media (max-width: 1399px) {}

@media (max-width: 1199px) {
  .rtl .worker-logo {
    left: -90px !important;
  }
}

@media (max-width: 991px) {
  .rtl .particals {
    right: 900px;
  }

  .rtl .worker-logo {
    left: 100px !important;
    right: auto !important;
    margin: auto;
  }

  .rtl .after::before {
    content: "";
    position: absolute;
    width: 60px;
    height: 1px;
    top: 0px;
    bottom: 0;
    left: 130px;
    right: auto !important;
    margin: auto;
    background-color: var(--darkColor);
  }
}

@media (max-width: 768px) {
  .rtl .particals {
    right: 700px;
  }
}
@media (max-width: 768px) {
  .rtl .worker-logo{
    display: none;
  }
}

@media (max-width: 576px) {
  .rtl .particals {
    right: 570px;
  }
}

@media (max-width: 480px) {

  .rtl .abtColContent {
    order: 2;
    padding-left: 0px !important;
  }

  .rtl .particals {
    right: 470px;
  }
}

@media (max-width: 400px) {

  .rtl .particals {
    right: 400px;
  }

  .rtl .before_after::before {
    right: auto !important;
    left: -35px;
  }

  .rtl .before_after::after {
    left: auto !important;
    right: -35px;
  }

  .rtl .after::before {
    right: auto !important;
    left: 100px !important;
  }

  .rtl .after::after {
    left: auto !important;
    right: 100px !important;
  }

  .rtl .project-other .project-bg {
    position: absolute;
    bottom: 0px;
    right: auto !important;
    left: 20px !important;
    width: 100px !important;
  }
}